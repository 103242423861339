<!--
File: Report_6_ListOfSections.vue
Description: produce the list of DEUs, and Regions under RMD for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='onRegionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown class='region-select-dropdown' :label="$t('label.select_deu')" v-model="selectedDeu"
            :initial_value="selectedDeu" :region_id="selectedRegion" @input='onDeuChange' :disabled='!selectedRegion' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadClassesDropdown :label="$t('road_network.road_class')" v-model="selectedRoadClass"
            :initial_value=selectedRoadClass @input='onRoadClassChange' />
        </div>
        <!--<div class="md-layout-item md-small-size-100 md-size-20">
            <RoadsDropdown :label="$t('label.select_road')" v-model="selectedRoad"
              data-vv-name="selectedRoad" :initial_value=selectedRoad />
          </div>-->
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-datepicker v-model="selectedDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.select_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" style="max-width: 200px;" :md-label="$t('road_network.region')"
            md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')" md-sort-by="deu_description">
            {{ $t('road_network.dep') + '-' + item.deu_description }}
          </md-table-cell>
          <md-table-cell style="min-width: 40%;" :md-label="$t('road_network.section_description')"
            md-sort-by="section_description">
            <a href='#' @click.stop.prevent='showSections(item)'> {{ item.section_description }}</a>
          </md-table-cell>
          <!--<md-table-cell :md-label="$t('inventory.start_place')" md-sort-by="start_place">
            {{ item.start_place }}
          </md-table-cell>
          <md-table-cell :md-label="$t('inventory.end_place')" md-sort-by="end_place">
            {{ item.end_place }}
          </md-table-cell>-->
          <md-table-cell :md-label="$t('road_network.start_km')" md-sort-by="start_distance_km" md-numeric>
            {{ item.start_distance_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_km')" md-sort-by="end_distance_km" md-numeric>
            {{ item.end_distance_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length_km')" md-sort-by="length_km" md-numeric>
            {{ item.length_km | numFormat(3) }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <md-table>
        <md-table-row>
          <md-table-head colspan="col_span">{{ $t('road_network.total_length') }}</md-table-head>
          <md-table-head md-numeric>{{ total_length }}</md-table-head>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
  import RoadClassesDropdown from '../Dropdowns/RoadClassesDropdown.vue'
  import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { customSortMixin } from '@/mixins/customSortMixin'
  import printReport from '@/mixins/printMixin'

  export default {
    mixins: [customSortMixin, printReport],
    name: 'report-list-of-sections',
    data() {
      return {
        selectedRegion: null,
        selectedDeu: null,
        selectedRoadClass: null,
        selectedDate: new Date(),
        selectedRoad: '',
        btnDisabled: true,

        showSpinner: false,
        regionDescr: null,
        currentSort: 'region_description',
        currentSortOrder: 'asc'
      }
    },
    components: {
      RegionsDropdown,
      DepsDropdown,
      RoadClassesDropdown,
      RoadsDropdown
    },

    methods: {
      reloadData() {
        this.showSpinner = true
        this.btnDisabled = true
        const report_params = {
          region_id: this.selectedRegion,
          deu_id: this.selectedDeu,
          road_class: this.selectedRoadClass,
          selectedDate: this.selectedDate.toISOString().substring(0, 10)
        }
        this.$store.dispatch('REPORT_6_ROAD_SECTIONS', report_params).then(() => {
          this.btnDisabled = false
          this.showSpinner = false
        })
      },
      print() {
        this.showSpinner = true
        let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('label.report6_title')
        prtHtml += '&nbsp;' + this.$t('label.as_on') + this.selectedDate.toLocaleDateString()

        let titleFilters = ''
        let tableHeaders = '<tr>'
        if (this.selectedRegion) {
          titleFilters += this.$t('label.from_region', { name: this.regionDescr })
        } else {
          tableHeaders += `<th>${this.$t('road_network.region')}</th>`
        }
        if (this.selectedDeu) {
          if (titleFilters !== '') titleFilters += ', &nbsp;'
          titleFilters += this.$t('road_network.dep') + ': ' + this.deuDescr
        } else {
          tableHeaders += `<th>${this.$t('road_network.dep')}</th>`
        }
        if (this.selectedRoadClass) {
          if (titleFilters !== '') titleFilters += ', &nbsp;'
          titleFilters += this.$t('road_network.road_class') + ': ' + this.selectedRoadClass
        } else {
          tableHeaders += `<th>${this.$t('road_network.road_class')}</th>`
        }
        if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
        prtHtml += '</h4>'

        tableHeaders += `<th>${this.$t('road_network.section_description')}</th>
            <th>${this.$t('road_network.start_km')}</th>
            <th>${this.$t('road_network.end_km')}</th>
            <th>${this.$t('road_network.length_km')}</th>
        </tr>`

        let tableRows = ''
        this.report.forEach(item => {
          tableRows += '<tr>'
          if (!this.selectedRegion) tableRows += `<td>${item.region_description}</td>`
          if (!this.selectedDeu) tableRows += `<td>${this.$t('road_network.dep') + '-' + item.deu_description}</td>`
          if (!this.selectedRoadClass) tableRows += `<td>${item.road_class}</td>`
          tableRows += `<td>${item.section_description}</td>
            <td class='numCell'>${numFormat(item.start_distance_km, 3)}</td>
            <td class='numCell'>${numFormat(item.end_distance_km, 3)}</td>
            <td class='numCell'>${numFormat(item.length_km, 3)}</td>
          </tr>`
        })

        tableRows += `<tr>
          <th colspan=${this.col_span}>${this.$t('road_network.total_length')}</th>
            <th class='numCell'>${this.total_length}</th>
        </tr>`

        this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
      },
      onRegionChange(region_id, regionDescr) {
        this.regionDescr = regionDescr
        this.reloadData()
      },
      onDeuChange(deu_id, deuDescr) {
        this.deuDescr = deuDescr
        this.reloadData()
      },
      onRoadClassChange() {
        this.reloadData()
      },
      showSections(item) {
        const hist = {
          selectedRegion: item.region_id,
          selectedDeu: item.deu_id,
          selectedRoad: item.road_id,
          perPage: 20,
          currentPage: 1
        }
        this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'sections' })
        this.$store.dispatch('SET_USE_HISTORY')
        this.$router.push('/inventory_data/sections')
      },
    },

    computed: {
      report() {
        return this.customSort(this.$store.state.Reports.report_6_data, 'deu_description')
      },
      tableData() {
        let result = !this.selectedRegion ? this.report : this.report.filter((item) => {
          return item.region_id == this.selectedRegion
        })
        if (this.selectedDeu) {
          result = result.filter((item) => { return item.deu_id == this.selectedDeu })
        }
        if (this.selectedRoadClass) {
          result = result.filter((item) => { return item.road_class == this.selectedRoadClass })
        }
        if (this.selectedRoad) {
          result = result.filter((item) => { return item.road_id == this.selectedRoad })
        }
        return result
      },
      total_length() {
        return numFormat(this.report.reduce((acc, currVal) => acc + currVal.length_km, 0,))
      },
      col_span() {
        let res = 6
        if (this.selectedRegion) res--
        if (this.selectedDeu) res--
        if (this.selectedRoadClass) res--

        return res
      }
    },

    watch: {
      selectedDate(newValue) {
        if (!newValue) this.selectedDate = new Date()
        else this.reloadData()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}</style>